/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ColorSchemeName, Platform } from 'react-native';
import { useTheme } from 'react-native-paper';

import NotFoundScreen from '../screens/NotFoundScreen';
import { RootStackParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import { Routes } from './routes';
import CalculatorScreen from '../screens/CalculatorScreen';
import SettingsScreen from '../screens/SettingsScreen';
import PrivacyScreen from '../screens/PrivacyScreen';
import MarketingScreen from '../screens/MarketingScreen';
import SupportScreen from '../screens/SupportScreen';
import HomeScreen from '../screens/HomeScreen';
import TermsScreen from '../screens/TermsScreen';
import { NavigationLightTheme, NavigationDarkTheme } from '../theme';

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={
        colorScheme === 'dark' ? NavigationDarkTheme : NavigationLightTheme
      }
    >
      {Platform.OS === 'web' ? <WebNavigator /> : <RootNavigator />}
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  const { colors } = useTheme();
  return (
    <Stack.Navigator
      initialRouteName={Routes.Calculator}
      mode="modal"
      screenOptions={{ headerShown: true }}
    >
      <Stack.Screen
        name={Routes.Calculator}
        component={CalculatorScreen}
        options={
          { 
            title: "Buy Price",
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: colors.black,
            },
            headerTintColor: colors.white,
            headerTitleStyle: {
              fontSize: 24,
            },
          }
        }
      />
      <Stack.Screen name={Routes.Settings} component={SettingsScreen} />
      <Stack.Screen
        name={Routes.NotFound}
        component={NotFoundScreen}
        options={{ title: 'Oops!' }}
      />
    </Stack.Navigator>
  );
}

function WebNavigator() {
  const { colors } = useTheme();
  return (
    <Stack.Navigator
      initialRouteName={Routes.Home}
      mode="modal"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name={Routes.Home} component={HomeScreen} />
      <Stack.Screen name={Routes.Marketing} component={MarketingScreen} />
      <Stack.Screen name={Routes.Privacy} component={PrivacyScreen} />
      <Stack.Screen name={Routes.Support} component={SupportScreen} />
      <Stack.Screen name={Routes.Terms} component={TermsScreen} />
      <Stack.Screen
        name={Routes.NotFound}
        component={NotFoundScreen}
        options={{ title: 'Oops!' }}
      />
    </Stack.Navigator>
  );
}
