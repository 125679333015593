/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      calculator: 'calculator',
      home: '',
      marketing: 'marketing',
      privacy: 'privacy',
      settings: 'settings',
      support: 'support',
      terms: 'terms',
      notfound: '*',
    },
  },
};
