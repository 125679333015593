export enum SettingsActionTypes {
  SET_BUY_MAKER_FEE = 'SET_BUY_MAKER_FEE',
  SET_BUY_TAKER_FEE = 'SET_BUY_TAKER_FEE',
  SET_SELL_MAKER_FEE = 'SET_SELL_MAKER_FEE',
  SET_SELL_TAKER_FEE = 'SET_SELL_TAKER_FEE',
  SET_BUY_FEE_USE_MAKER = 'SET_BUY_FEE_USE_MAKER',
  SET_SELL_FEE_USE_MAKER = 'SET_SELL_FEE_USE_MAKER',
}

interface SetBuyMakerFeeAction {
  type: typeof SettingsActionTypes.SET_BUY_MAKER_FEE;
  fee: string;
}

interface SetBuyTakerFeeAction {
  type: typeof SettingsActionTypes.SET_BUY_TAKER_FEE;
  fee: string;
}

interface SetSellMakerFeeAction {
  type: typeof SettingsActionTypes.SET_SELL_MAKER_FEE;
  fee: string;
}

interface SetSellTakerFeeAction {
  type: typeof SettingsActionTypes.SET_SELL_TAKER_FEE;
  fee: string;
}

interface SetBuyFeeUseMakerAction {
  type: typeof SettingsActionTypes.SET_BUY_FEE_USE_MAKER;
  value: boolean;
}

interface SetSellFeeUseMakerAction {
  type: typeof SettingsActionTypes.SET_SELL_FEE_USE_MAKER;
  value: boolean;
}

export type SettingsAction =
  | SetBuyMakerFeeAction
  | SetBuyTakerFeeAction
  | SetSellMakerFeeAction
  | SetSellTakerFeeAction
  | SetBuyFeeUseMakerAction
  | SetSellFeeUseMakerAction;

export interface SettingsState {
  buyMakerFee: string;
  buyTakerFee: string;
  sellMakerFee: string;
  sellTakerFee: string;
  buyFeeUseMaker: boolean;
  sellFeeUseMaker: boolean;
}
