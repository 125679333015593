export enum CalculatorActionTypes {
  SET_BUY_PRICE = 'SET_BUY_PRICE',
  SET_TARGET_PROFIT_PERCENTAGE = 'SET_TARGET_PROFIT_PERCENTAGE',
}

interface SetBuyPriceAction {
  type: typeof CalculatorActionTypes.SET_BUY_PRICE;
  price: string;
}

interface SetTargetProfitPercentageAction {
  type: typeof CalculatorActionTypes.SET_TARGET_PROFIT_PERCENTAGE;
  value: number;
}

export type CalculatorAction =
  | SetBuyPriceAction
  | SetTargetProfitPercentageAction;

export interface CalculatorState {
  buyPrice: string;
  targetProfitPercentage: number;
}
