import { combineReducers } from 'redux';

import calculator from './calculator/reducer';
import settings from './settings/reducer';

const reducer = combineReducers({
  calculator: calculator,
  settings: settings,
});

export default reducer;
