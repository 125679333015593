import * as React from 'react';
import { StyleSheet, View, Platform, StatusBar } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  List,
  TextInput,
  withTheme,
  IconButton,
  Text,
} from 'react-native-paper';
import { connect, ConnectedProps } from 'react-redux';
import { NavigationParams } from 'react-navigation';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { RootState } from '../store/types';
import {
  settingsSetBuyMakerFee,
  settingsSetBuyTakerFee,
  settingsSetSellMakerFee,
  settingsSetSellTakerFee,
  settingsSetBuyFeeUseMaker,
  settingsSetSellFeeUseMaker,
} from '../store/settings/actions';
import NumberInput from '../components/NumberInput';
import useColorScheme from '../hooks/useColorScheme';

const mapStateToProps = (state: RootState) => ({
  buyMakerFee: state.settings.buyMakerFee,
  buyTakerFee: state.settings.buyTakerFee,
  sellMakerFee: state.settings.sellMakerFee,
  sellTakerFee: state.settings.sellTakerFee,
  buyFeeUseMaker: state.settings.buyFeeUseMaker,
  sellFeeUseMaker: state.settings.sellFeeUseMaker,
});

const mapDispatchToProps = {
  setBuyMakerFee: settingsSetBuyMakerFee,
  setBuyTakerFee: settingsSetBuyTakerFee,
  setSellMakerFee: settingsSetSellMakerFee,
  setSellTakerFee: settingsSetSellTakerFee,
  setBuyFeeUseMaker: settingsSetBuyFeeUseMaker,
  setSellFeeUseMaker: settingsSetSellFeeUseMaker,
};

// eslint-disable-next-line prettier/prettier
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  navigation: NavigationParams;
  theme: ReactNativePaper.Theme;
};

function SettingsScreen({
  navigation,
  theme,
  buyMakerFee,
  buyTakerFee,
  sellMakerFee,
  sellTakerFee,
  buyFeeUseMaker,
  sellFeeUseMaker,
  setBuyMakerFee,
  setBuyTakerFee,
  setSellMakerFee,
  setSellTakerFee,
  setBuyFeeUseMaker,
  setSellFeeUseMaker,
}: Props) {
  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: null,
      headerRight: () => (
        <IconButton icon="close" onPress={() => navigation.goBack()} />
      ),
    });
  }, [navigation]);

  const colorScheme = useColorScheme();

  return (
    <SafeAreaView style={styles.safeArea} edges={['right', 'bottom', 'left']}>
      <StatusBar
        barStyle={colorScheme === 'dark' ? 'light-content' : 'dark-content'}
      />
      <KeyboardAwareScrollView
        style={styles.scrollView}
        extraScrollHeight={48}
        keyboardDismissMode={Platform.OS === 'ios' ? 'interactive' : 'on-drag'}
        keyboardShouldPersistTaps="handled"
      >
        <List.Section>
          <List.Subheader>Buy</List.Subheader>
          <List.Item
            title="Maker"
            onPress={() => setBuyFeeUseMaker(true)}
            right={(props) => (
              <NumberInput
                onChangeText={(text) => setBuyMakerFee(text)}
                value={buyMakerFee}
              />
            )}
            left={(props) => (
              <List.Icon
                {...props}
                style={{ alignSelf: 'center'}}
                icon={buyFeeUseMaker ? 'radiobox-marked' : 'radiobox-blank'}
                color={
                  buyFeeUseMaker ? theme.colors.primary : theme.colors.text
                }
              />
            )}
          />
          <List.Item
            title="Taker"
            onPress={() => setBuyFeeUseMaker(false)}
            right={(props) => (
              <NumberInput
                onChangeText={(text) => setBuyTakerFee(text)}
                value={buyTakerFee}
              />
            )}
            left={(props) => (
              <List.Icon
                {...props}
                style={{ alignSelf: 'center'}}
                icon={buyFeeUseMaker ? 'radiobox-blank' : 'radiobox-marked'}
                color={
                  buyFeeUseMaker ? theme.colors.text : theme.colors.primary
                }
              />
            )}
          />
        </List.Section>
        <List.Section>
          <List.Subheader>Sell</List.Subheader>
          <List.Item
            title="Maker"
            onPress={() => setSellFeeUseMaker(true)}
            right={(props) => (
              <NumberInput
                onChangeText={(text) => setSellMakerFee(text)}
                value={sellMakerFee}
              />
            )}
            left={(props) => (
              <List.Icon
                {...props}
                style={{ alignSelf: 'center'}}
                icon={sellFeeUseMaker ? 'radiobox-marked' : 'radiobox-blank'}
                color={
                  sellFeeUseMaker ? theme.colors.primary : theme.colors.text
                }
              />
            )}
          />
          <List.Item
            title="Taker"
            onPress={() => setSellFeeUseMaker(false)}
            right={(props) => (
              <NumberInput
                onChangeText={(text) => setSellTakerFee(text)}
                value={sellTakerFee}
              />
            )}
            left={(props) => (
              <List.Icon
                {...props}
                style={{ alignSelf: 'center'}}
                icon={sellFeeUseMaker ? 'radiobox-blank' : 'radiobox-marked'}
                color={
                  sellFeeUseMaker ? theme.colors.text : theme.colors.primary
                }
              />
            )}
          />
        </List.Section>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
});

export default withTheme(connector(SettingsScreen));
