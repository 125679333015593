import { CalculatorAction, CalculatorActionTypes } from './types';

// For safety, only expose actions that are intended to be used
// outside of the store.
export const calculatorSetBuyPrice = (price: string): CalculatorAction => ({
  type: CalculatorActionTypes.SET_BUY_PRICE,
  price: price,
});

export const calculatorSetTargetProfitPercentage = (
  value: number
): CalculatorAction => ({
  type: CalculatorActionTypes.SET_TARGET_PROFIT_PERCENTAGE,
  value: value,
});
