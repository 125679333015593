export const Routes = {
  Calculator: 'calculator',
  Home: 'home',
  Marketing: 'marketing',
  Privacy: 'privacy',
  Settings: 'settings',
  Support: 'support',
  Terms: 'terms',
  NotFound: 'notfound',
};
