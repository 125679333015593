import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

import { RootStackParamList } from '../types';
import Header from '../components/Header';

export default function SupportScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'Support'>) {
  return (
    <View style={styles.container}>
      <Header navigation={navigation} />
      <Text style={styles.title}>Email</Text>
      <TouchableOpacity onPress={() => WebBrowser.openBrowserAsync('mailto: support@rossgibson.dev')} style={styles.link}>
        <Text style={styles.linkText}>support@rossgibson.dev</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  link: {
    // marginHorizontal: 15,
  },
  linkText: {
    fontSize: 14,
    color: 'blue',
  },
});
