import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { HelperText, TextInput } from 'react-native-paper';

type TextInputProps = React.ComponentProps<typeof TextInput>;

type Props = TextInputProps & {};

const NumberInput = ({ style, ...textInputProps }: Props) => {
  const [errorMessage, setErrorMessage] =
    React.useState<string | undefined>(undefined);

  function handleOnChangeText(text: string) {
    if (text.length > 0) {
      if (Math.sign(text as any) === -1) {
        if (parseFloat(text) < 0.0) {
          setErrorMessage('Negative');
        } else {
          setErrorMessage(undefined);
        }
      } else if (Math.sign(text as any) === -0) {
        if (parseFloat(text) < 0) {
          setErrorMessage('Negative');
        } else {
          setErrorMessage(undefined);
        }
      } else if (isNaN(Math.sign(text as any))) {
        setErrorMessage('Not a number');
      } else {
        setErrorMessage(undefined);
      }
    } else {
      setErrorMessage(undefined);
    }

    const { onChangeText } = textInputProps;
    onChangeText && onChangeText(text);
  }

  return (
    <View style={styles.container}>
      <TextInput
        {...textInputProps}
        style={[styles.input, { textAlign: 'center' }]}
        keyboardType={'decimal-pad'}
        onChangeText={(text) => handleOnChangeText(text)}
      />
      <HelperText type="error" visible={errorMessage !== undefined}>
        {errorMessage}
      </HelperText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    backgroundColor: 'transparent',
  },
});

export default NumberInput;
