import 'react-native-gesture-handler'; // This must be the first import.
// See docs: https://reactnavigation.org/docs/getting-started/

import React, { ReactNode } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider as ThemeProvider } from 'react-native-paper';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from 'sentry-expo';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import configureStore from './store/utils/configureStore';
import { AppLightTheme, AppDarkTheme } from './theme';

// See: https://docs.expo.dev/guides/using-sentry/
Sentry.init({
  dsn: 'https://5b59ea5d1de54a8390c372ddec03e45a@o1027873.ingest.sentry.io/5994811',
  enableInExpoDevelopment: false,
  debug: false, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
});

// if (__DEV__) {
//   // Reactotron does not work on the web
//   import('./store/utils/ReactotronConfig').then(() => console.log('Reactotron Configured'))
// }

 export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const { store, persistor } = configureStore();

  function storeContainer(children: ReactNode | ReactNode[]) {
    // TODO: Add loading: https://github.com/rt2zz/redux-persist
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {children}
        </PersistGate>
      </Provider>
    );
  }

  function themeContainer(children: ReactNode | ReactNode[]) {
    return (
      <ThemeProvider
        theme={colorScheme === 'dark' ? AppDarkTheme : AppLightTheme}
      >
        {children}
      </ThemeProvider>
    );
  }

  if (!isLoadingComplete) {
    return null;
  } else {
    return storeContainer(
      themeContainer(
        <SafeAreaProvider>
          <Navigation colorScheme={colorScheme} />
        </SafeAreaProvider>
      )
    );
  }
}
