import {
  CalculatorAction,
  CalculatorState,
  CalculatorActionTypes,
} from './types';

const initialState: CalculatorState = {
  buyPrice: '100',
  targetProfitPercentage: 1,
};

const calculator = (state = initialState, action: CalculatorAction) => {
  switch (action.type) {
    case CalculatorActionTypes.SET_BUY_PRICE:
      return { ...state, buyPrice: action.price };
    case CalculatorActionTypes.SET_TARGET_PROFIT_PERCENTAGE:
      return { ...state, targetProfitPercentage: action.value };
    default:
      return state;
  }
};

export default calculator;
