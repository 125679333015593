import { SettingsAction, SettingsActionTypes } from './types';

// For safety, only expose actions that are intended to be used
// outside of the store.
export const settingsSetBuyMakerFee = (fee: string): SettingsAction => ({
  type: SettingsActionTypes.SET_BUY_MAKER_FEE,
  fee: fee,
});

export const settingsSetBuyTakerFee = (fee: string): SettingsAction => ({
  type: SettingsActionTypes.SET_BUY_TAKER_FEE,
  fee: fee,
});

export const settingsSetSellMakerFee = (fee: string): SettingsAction => ({
  type: SettingsActionTypes.SET_SELL_MAKER_FEE,
  fee: fee,
});

export const settingsSetSellTakerFee = (fee: string): SettingsAction => ({
  type: SettingsActionTypes.SET_SELL_TAKER_FEE,
  fee: fee,
});

export const settingsSetBuyFeeUseMaker = (value: boolean): SettingsAction => ({
  type: SettingsActionTypes.SET_BUY_FEE_USE_MAKER,
  value: value,
});

export const settingsSetSellFeeUseMaker = (value: boolean): SettingsAction => ({
  type: SettingsActionTypes.SET_SELL_FEE_USE_MAKER,
  value: value,
});
