import * as React from 'react';
import { NavigationParams } from 'react-navigation';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';

import { Routes } from '../navigation/routes';

type Props = {
  navigation: NavigationParams;
};

const Header = ({ navigation }: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <TouchableOpacity onPress={() => navigation.navigate(Routes.Home)} style={styles.link}>
          <Text style={styles.linkText}>Percents</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.rightContainer}>
        <TouchableOpacity onPress={() => navigation.navigate(Routes.Privacy)} style={styles.link}>
          <Text style={styles.linkText}>Privacy</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate(Routes.Terms)} style={styles.link}>
          <Text style={styles.linkText}>Terms</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate(Routes.Support)} style={styles.link}>
          <Text style={styles.linkText}>Support</Text>
        </TouchableOpacity>
      </View>
      </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems : 'center',
    justifyContent: 'flex-start',
  },
  rightContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems : 'center',
    justifyContent: 'flex-end',
  },
  link: {
    marginHorizontal: 20,
  },
  linkText: {
    fontSize: 14,
    color: '#000',
  },
});

export default Header;
