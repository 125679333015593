import { SettingsAction, SettingsState, SettingsActionTypes } from './types';

const initialState: SettingsState = {
  buyMakerFee: '0.075',
  buyTakerFee: '0.075',
  sellMakerFee: '0.075',
  sellTakerFee: '0.075',
  buyFeeUseMaker: false,
  sellFeeUseMaker: false,
};

const settings = (state = initialState, action: SettingsAction) => {
  switch (action.type) {
    case SettingsActionTypes.SET_BUY_MAKER_FEE:
      return { ...state, buyMakerFee: action.fee };
    case SettingsActionTypes.SET_BUY_TAKER_FEE:
      return { ...state, buyTakerFee: action.fee };
    case SettingsActionTypes.SET_SELL_MAKER_FEE:
      return { ...state, sellMakerFee: action.fee };
    case SettingsActionTypes.SET_SELL_TAKER_FEE:
      return { ...state, sellTakerFee: action.fee };
    case SettingsActionTypes.SET_BUY_FEE_USE_MAKER:
      return { ...state, buyFeeUseMaker: action.value };
    case SettingsActionTypes.SET_SELL_FEE_USE_MAKER:
      return { ...state, sellFeeUseMaker: action.value };
    default:
      return state;
  }
};

export default settings;
