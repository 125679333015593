import { DefaultTheme } from 'react-native-paper';
import { Theme as NavigationTheme } from '@react-navigation/native';

declare global {
  namespace ReactNativePaper {
    interface ThemeColors {
      black: string;
      white: string;
    }

    interface Theme {
      myOwnProperty: boolean;
    }
  }
}

export const AppLightTheme: ReactNativePaper.Theme = {
  dark: false, // whether this is a dark theme or light theme.
  mode: 'adaptive',
  roundness: 3, // roundness of common elements, such as buttons.
  colors: {
    primary: '#F712A6', // primary color for your app, usually your brand color.
    background: '#FFFFFF', // background color for pages, such as lists.
    surface: '#F9F9F9', // background color for elements containing content, such as cards.
    accent: '#FBBC05', // secondary color for your app which complements the primary color.
    error: '#EA4335',
    text: '#000000', // text color for content.
    onSurface: '#000000',
    disabled: 'rgba(0, 0, 0, 0.26)', // color for disabled elements.
    placeholder: 'rgba(0, 0, 0, 0.54)', // color for placeholder text, such as input placeholder.
    backdrop: 'rgba(0, 0, 0, 0.5)', // color for backdrops of various components such as modals.
    notification: '#34A853',
    black: '#000000',
    white: '#FFFFFF',
  },
  fonts: DefaultTheme.fonts,
  animation: {
    scale: 1.0,
  },
  myOwnProperty: true, // TODO: Remove or use, this is just an example of custom properties in RN Paper v4
};

export const AppDarkTheme: ReactNativePaper.Theme = {
  dark: true,
  mode: 'adaptive',
  roundness: 3,
  colors: {
    primary: '#F712A6', // primary color for your app, usually your brand color.
    background: '#000000', // background color for pages, such as lists.
    surface: '#7B7B7B', // background color for elements containing content, such as cards.
    accent: '#FBBC05', // secondary color for your app which complements the primary color.
    error: '#EA4335',
    text: '#FFFFFF', // text color for content.
    onSurface: '#FFFFFF',
    disabled: 'rgba(255, 255, 255, 0.3)', // color for disabled elements.
    placeholder: 'rgba(255, 255, 255, 0.54)', // color for placeholder text, such as input placeholder.
    backdrop: 'rgba(0, 0, 0, 0.5)', // color for backdrops of various components such as modals.
    notification: '#34A853',
    black: '#000000',
    white: '#FFFFFF',
  },
  fonts: DefaultTheme.fonts,
  animation: {
    scale: 1.0,
  },
  myOwnProperty: true, // TODO: Remove or use, this is just an example of custom properties in RN Paper v4
};

export const NavigationLightTheme: NavigationTheme = {
  dark: false,
  colors: {
    primary: AppLightTheme.colors.primary, // The primary color of the app used to tint various elements.
    background: AppLightTheme.colors.background, // The color of various backgrounds, such as background color for the screens.
    card: AppLightTheme.colors.background, // The background color of card-like elements, such as headers, tab bars etc.
    text: AppLightTheme.colors.text, // The text color of various elements.
    border: AppLightTheme.colors.backdrop, // The color of borders, e.g. header border, tab bar border etc.
    notification: AppLightTheme.colors.text, // The text color of various elements.
  },
};

export const NavigationDarkTheme: NavigationTheme = {
  dark: true,
  colors: {
    primary: AppDarkTheme.colors.primary, // The primary color of the app used to tint various elements.
    background: AppDarkTheme.colors.background, // The color of various backgrounds, such as background color for the screens.
    card: AppDarkTheme.colors.background, // The background color of card-like elements, such as headers, tab bars etc.
    text: AppDarkTheme.colors.text, // The text color of various elements.
    border: AppDarkTheme.colors.backdrop, // The color of borders, e.g. header border, tab bar border etc.
    notification: AppDarkTheme.colors.text, // The text color of various elements.
  },
};
