import * as React from 'react';
import { StyleSheet, View, Keyboard, Dimensions, Pressable, StatusBar } from 'react-native';
import { connect, ConnectedProps } from 'react-redux';
import { NavigationParams } from 'react-navigation';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  withTheme,
  IconButton,
  Button,
  TextInput,
  Text,
  Snackbar,
  List,
  Headline,
  Caption,
} from 'react-native-paper';
import * as Clipboard from 'expo-clipboard';
import {
  BottomSheetModal,
  BottomSheetModalProvider,
  BottomSheetScrollView,
  BottomSheetBackdropProps,
  BottomSheetBackdrop,
} from '@gorhom/bottom-sheet';

import { RootState } from '../store/types';
import {
  calculatorSetBuyPrice,
  calculatorSetTargetProfitPercentage,
} from '../store/calculator/actions';
import { Routes } from '../navigation/routes';
import NumberInput from '../components/NumberInput';
import { AppLightTheme } from '../theme';

const mapStateToProps = (state: RootState) => ({
  buyMakerFee: state.settings.buyMakerFee,
  buyTakerFee: state.settings.buyTakerFee,
  sellMakerFee: state.settings.sellMakerFee,
  sellTakerFee: state.settings.sellTakerFee,
  buyFeeUseMaker: state.settings.buyFeeUseMaker,
  sellFeeUseMaker: state.settings.sellFeeUseMaker,
  buyPrice: state.calculator.buyPrice,
  targetProfitPercentage: state.calculator.targetProfitPercentage,
});

const mapDispatchToProps = {
  setBuyPrice: calculatorSetBuyPrice,
  setTargetProfitPercentage: calculatorSetTargetProfitPercentage,
};

// eslint-disable-next-line prettier/prettier
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  navigation: NavigationParams;
  theme: ReactNativePaper.Theme;
};

function CalculatorScreen({
  navigation,
  theme,
  buyMakerFee,
  buyTakerFee,
  sellMakerFee,
  sellTakerFee,
  buyFeeUseMaker,
  sellFeeUseMaker,
  buyPrice,
  targetProfitPercentage,
  setBuyPrice,
  setTargetProfitPercentage,
}: Props) {
  const [snackbarVisible, setSnackbarVisible] = React.useState(false);

  const deleteSymbol = '\u232B';
  const decimalSymbol = '\u002E'

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <IconButton
          icon="cog-outline"
          color={theme.colors.primary}
          onPress={() => navigation.navigate(Routes.Settings)}
        />
      ),
    });
  }, [navigation]);

  // ref
  const bottomSheetModalRef = React.useRef<BottomSheetModal>(null);

  // variables
  interface Percentage {
    title: string;
    value: number;
  }

  const data: Percentage[] = [
    {
      title: '10%',
      value: 10,
    },
    {
      title: '5%',
      value: 5,
    },
    {
      title: '4%',
      value: 4,
    },
    {
      title: '3%',
      value: 3,
    },
    {
      title: '2%',
      value: 2,
    },
    {
      title: '1%',
      value: 1,
    },
    {
      title: '0%',
      value: 0,
    },
    {
      title: '-1%',
      value: -1,
    },
    {
      title: '-3%',
      value: -3,
    },
    {
      title: '-5%',
      value: -5,
    },
  ];

  const snapPoints = React.useMemo(() => ['80%', '80%'], []);

  // callbacks
  const handlePresentModalPress = React.useCallback(() => {
    bottomSheetModalRef.current?.present();
  }, []);
  const handleSheetChanges = React.useCallback((index: number) => {
    console.log('handleSheetChanges', index);
  }, []);

  const styles = getStyles(theme);

  const renderItem = (item: Percentage) => (
    <List.Item
      key={item.value}
      style={styles.itemContainer}
      title={item.title}
      titleStyle={styles.itemTitleStyle}
      onPress={() => setTargetProfitPercentage(item.value)}
      left={() => (
        <List.Icon
          color={item.value === targetProfitPercentage ? theme.colors.primary : theme.colors.black}
          icon={
            item.value === targetProfitPercentage
              ? 'radiobox-marked'
              : 'radiobox-blank'
          }
        />
      )}
    />
  );

  const renderKeyboardGridItem = (digit: string) => {
    return (
      <Pressable style={styles.keyboardGridItem} key={digit} onPress={() => handleDigitPressed(digit)} disabled={digit === decimalSymbol && buyPrice.includes(decimalSymbol)}>
        <Text style={styles.keyboardDigit}>{digit}</Text>
      </Pressable>
    );
  };

  const countDecimals = (value: number) => { 
    return value % 1 != 0 ? value.toString().split(".")[1].length : 0;
  };

  function getSellPrice(): string {
    const buyPriceValue = parseFloat(buyPrice);
    const dp = countDecimals(buyPriceValue);

    const buyFee = buyFeeUseMaker
      ? parseFloat(buyMakerFee)
      : parseFloat(buyTakerFee);
    const sellFee = sellFeeUseMaker
      ? parseFloat(sellMakerFee)
      : parseFloat(sellTakerFee);
    const sellPriceValue =
      (buyPriceValue +
        buyPriceValue * (buyFee / 100) +
        buyPriceValue * (targetProfitPercentage / 100)) /
      (1 - sellFee / 100);

    return isNaN(sellPriceValue) ? '' : sellPriceValue.toFixed(dp + 2).toString();
  }

  const copyToClipboard = (text: string) => {
    Clipboard.setString(text);
    setSnackbarVisible(true);
  };

  const handlePercentagePressed = () => {
    handlePresentModalPress();
  };

  const handleDigitPressed = (digit: string) => {
    let value = buyPrice;
    if (digit === deleteSymbol && value.length > 0) {
      value = value.slice(0,-1);
      if (value.length < 1) {
        value = '0';
      }
    } else {
      if (value.length === 1 && value === '0' && digit !== decimalSymbol) {
        if (digit === '0') {
          value = '0.0';
        } else {
          value = digit;
        }
      } else {
        value = value.concat('', digit);
      }
    }
    setBuyPrice(value);
  };

  const renderBackdrop = React.useCallback(
    (props: BottomSheetBackdropProps) => <BottomSheetBackdrop {...props} />,
    []
  );

  const digits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', decimalSymbol, '0', deleteSymbol];

  return (
    <BottomSheetModalProvider>
      <SafeAreaView style={styles.safeArea} edges={['right', 'bottom', 'left']}>
      <StatusBar
            barStyle={'light-content'}
          />
        <View style={styles.container}>
          <View style={styles.topContainer}>
            <View style={styles.buyPriceTextInputContainer}>
              <Text style={styles.buyPriceTextInput} numberOfLines={1} adjustsFontSizeToFit={true} minimumFontScale={0.4}>{`${buyPrice}`}</Text>
            </View>
            <Pressable onPress={handlePercentagePressed}><Text style={styles.marginButton}>{`${targetProfitPercentage}% MARGIN`}</Text></Pressable>
            <View style={styles.sellPriceContainer}>
              <Text style={styles.sellPriceLabel}>Sell Price</Text>
              <Text style={styles.sellPriceValue}>{`${getSellPrice()}`}</Text>
            </View>
          </View>
          <View style={styles.bottomContainer}>
            <View style={styles.keyboardContainer}>
              {digits.map(renderKeyboardGridItem)}
            </View>
            {getSellPrice().length > 0 ? (
              <Button
                contentStyle={styles.copyButton}
                mode="contained"
                onPress={() => copyToClipboard(getSellPrice())}
              >
                {'Copy Sell Price'}
              </Button>
            ) : null}
          </View>
        </View>
      </SafeAreaView>
      <BottomSheetModal
        ref={bottomSheetModalRef}
        index={1}
        snapPoints={snapPoints}
        onChange={handleSheetChanges}
        backdropComponent={renderBackdrop}
      >
        <BottomSheetScrollView contentContainerStyle={styles.contentContainer}>
          {data.map(renderItem)}
        </BottomSheetScrollView>
      </BottomSheetModal>
      <Snackbar
        visible={snackbarVisible}
        duration={1500}
        onDismiss={() => setSnackbarVisible(false)}
        theme={AppLightTheme}
      >
        {`Copied: ${getSellPrice()}`}
      </Snackbar>
    </BottomSheetModalProvider>
  );
}

const windowWidth = Dimensions.get('window').width;
const keyboardPadding = 16;
const buttonHeight = 48;

const keyboardSize = windowWidth - 2 * keyboardPadding;

const getStyles = (theme: ReactNativePaper.Theme) => StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.black,
  },
  topContainer: {
    flex: 1,
    backgroundColor: theme.colors.black,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  bottomContainer: {
    height: windowWidth + buttonHeight + keyboardPadding,
    backgroundColor: theme.colors.white,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: keyboardPadding,
  },
  keyboardContainer: {
    height: keyboardSize,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  keyboardGridItem: {
    width: keyboardSize / 3,
    height: keyboardSize / 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  keyboardDigit: {
    fontSize: 24,
    color: theme.colors.black,
  },
  contentContainer: {
    alignItems: 'center',
  },
  buyPriceTextInput: {
    color: theme.colors.white,
    fontSize: 72,
  },
  buyPriceTextInputContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    height: 100, // We set a fixed hight to prevent "text wobble" as the font size scales
    marginHorizontal: 12,
  },
  sellPriceContainer: {
    alignItems: 'center',
  },
  sellPriceLabel: {
    color: theme.colors.white,
    fontSize: 16,
    marginBottom: 8,
  },
  sellPriceValue: {
    color: theme.colors.white,
    fontSize: 20,
  },
  marginButton: {
    color: theme.colors.primary,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    margin: 12,
  },
  percentageButton: {
    alignSelf: 'center',
  },
  copyButton: {
    height: 48,
  },
  itemContainer: {
    padding: 6,
    margin: 6,
    alignSelf: 'stretch',
  },
  itemTitleStyle: {
    color: theme.colors.black,
  },
});

export default withTheme(connector(CalculatorScreen));
