import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { RootStackParamList } from '../types';
import Header from '../components/Header';

export default function MarketingScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'Marketing'>) {
  return (
    <View style={styles.container}>
      <Header navigation={navigation} />
      <Text style={styles.title}>We don't do marketing</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  }
});
